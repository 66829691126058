body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lexend', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.MuiCollapse-root .MuiBox-root {
  display: none;
}



.ql-toolbar.ql-snow {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 5px;
  background-color: #F4F6F8;
}


::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddd; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bbb; 
}
